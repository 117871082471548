<template>
    <div class="text-center mb-5">
        <h3 class="text-2xl font-bold">
            {{ processing ? 'Очікування ключа доступу...' : 'Вхід за ключем доступу' }}
        </h3>
    </div>

    <InputError :message="errorMessage"/>

    <PrimaryButton @click="start" v-show="!processing" class="mt-5" color="indigo">
        Спробувати знову
    </PrimaryButton>

    <PrimaryButton
        :disabled="processing"
        @click="$emit('phoneAuth')"
        class="mt-5"
        color="white"
        text-color="black"
    >
        Увійти за номером телефону
    </PrimaryButton>
</template>

<script>
import {router, useForm} from '@inertiajs/vue3';
import {startAuthentication} from '@simplewebauthn/browser';
import {nextTick} from 'vue';
import InputError from "@/Components/InputError.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {track} from "@/Helpers/zaraz";

export default {
    components: {PrimaryButton, InputError},
    props: {
        publicKey: Object,
        remember: Boolean,
        returnBack: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            errorMessage: '',
            processing: false,
        }
    },
    watch: {
        publicKey(value) {
            this.errorMessage = '';
            this.loginWaitForKey(value);
        }
    },
    methods: {
        _errorMessage(name, message) {
            switch (name) {
                case 'InvalidStateError':
                    return 'Неочікувана помилка при вході.';
                case 'NotAllowedError':
                    return 'Операція прострочена або була заборонена.';
                default:
                    return message;
            }
        },
        start() {
            this.errorMessage = '';
            router.reload({only: ['publicKey']});
        },
        stop() {
            this.processing = false;
        },
        loginWaitForKey(publicKey) {
            this.processing = true;

            nextTick()
                .then(() => startAuthentication({optionsJSON: publicKey}))
                .then((data) => this.webauthnLoginCallback(data))
                .catch((error) => {
                    this.errorMessage = this._errorMessage(error.name, error.message);
                    this.stop();
                });
        },
        webauthnLoginCallback(data) {
            useForm({
                ...data,
                returnBack: this.returnBack,
                remember: this.remember ? 'on' : '',
            }).post(this.route('webauthn.auth'), {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => track('login', {method: 'Passkey'}),
                onError: (error) => {
                    this.errorMessage = error.message ?? error.response.data.errors[0];

                    this.stop();
                },
            });
        }
    },
    mounted() {
        this.loginWaitForKey(this.publicKey);
    }
}
</script>
